
input { 
    border-width: 2px;
    border-radius: 5px;
    border-color: lightgray;
    padding: 8px
}

input.accepted { 
    border-color: lightseagreen;
    border-width: 2px;
    border-radius: 5px;
    padding: 8px;
}
