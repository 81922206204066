table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  th, td {
    text-align: left;
    padding: 0.75rem;
    border-bottom: 1px solid #dee2e6;
  }
  
  th {
    background-color: #f8f9fa;
    font-weight: 600;
    border-top: 1px solid #dee2e6;
  }
  
  td {
    font-size: 0.875rem;
  }
  
  tbody tr:nth-of-type(even) {
    background-color: #f8f9fa;
  }
  
  a {
    color: #007bff;
  }