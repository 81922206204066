@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montserrat:wght@200;300;400;500&family=Noto+Sans+JP:wght@100;300;400;500&family=Open+Sans:wght@300;400;500&family=Poppins:wght@100;200;300;400;500;600;700&family=Raleway:wght@100;200;300;400;500&display=swap');

* { 
  font-family: 'Poppins';
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Set flexbox properties for large screens */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #00cc66 transparent #00cc66 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.htp-background-image { 
  background-image: url('./assets/HowToPlayBG.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

/* Used within prize details to break line */
.new-line { 
  white-space: pre-wrap;
}

/* Below classes required to ensure footer is always at bottom of screen */
.force-footer { 
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
  }
  
  /* Make the main content area grow to fill the available space */
  .main-content {
    flex-grow: 1;
  }

  /* Remove previous and next arrows for Slider component */
  .slick-prev,
.slick-next { 
  display:  none !important;
}


/* CART TABLE */

th, td {
  width: 20%; /* This will give each cell a base width of 33.33% */
}

.first-column {
  width: 60%; /* This will give the first column 33% of the table width */
}

th:not(.first-column), td:not(.first-column) {
  width: calc((100% - 50%) / 3); /* This will divide the remaining width evenly among the other columns. */
}


.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}



/* @media (min-width: 1200px) {
  .banner-image {
    max-width: 100%; 
  }
} */

/* @media (max-width: 768px) {
  .banner-container{ 
    padding: 0;
  }
  .banner-image { 
    width: 100%;
  } */
  /* .banner-container {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 200px; 
    padding-top: 10px;
  } */
  /* .banner-image { 
    height: 200px;
  } */
/* } */


.bg-polygon { 
  background-image: url('./assets/polygonScatter.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-polygonFilled { 
  background-image: url('./assets/polygonScatterFilled.svg');
  background-repeat: no-repeat;
  background-size: cover;
}


/* FAQ */

.faq {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.faq-item {
  margin-bottom: 15px;
  cursor: pointer;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.faq-answer.open {
  max-height: 400px;  
}


/* BANNER ON HOME PAGE */

/* styles/Banner.css */

.banner-container {
  position: relative;
  background-color: rgb(31, 41, 55);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  /* Adjust this to set the minimum height of the container */
  min-height: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.banner-image {
  cursor: pointer;
  
}

.pagination {
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 1rem 0;
}

@media (max-width: 768px) {
  .pagination { 
    gap: 0.4rem;
    padding-top: 2rem;
  }
}

.pagination button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #eee;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #ddd;
}

.pagination .active {
  background-color: #bbb;
}


.dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5%; /* Adjust as needed to move the dots up or down */
  left: 0; /* Starts from the left edge */
  right: 0; /* Ends at the right edge, ensuring it's centered */
  z-index: 1; /* This ensures the dots are above the image */
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #717171;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #bbb;
}

.moving-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.moving-banner-text {
  color: #fff;
  white-space: nowrap;
  animation: moveText 15s linear infinite;  /* Decrease duration to 10s for faster animation */
  position: relative; 
  font-size: 32px;
  font-weight: 500;
}

@keyframes moveText {
  0% {
    transform: translateX(100vw);  /* Starts just outside of view to the right */
  }
  100% {
    transform: translateX(-100%);  /* Ends just outside of view to the left */
  }
}


@media screen and (max-width: 700px) {
  .moving-banner-text {
    color: #fff;
    white-space: nowrap;
    animation: moveText 15s linear infinite;  /* Decrease duration to 10s for faster animation */
    position: relative; 
    font-size: 20px;
    font-weight: 500;
  }

  .moving-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}
